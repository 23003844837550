import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CardLayout from "../../common/CardLayout";
import ModAuthen from "../../common/ModAuthen";
import { prepareForm, QuestionSection, updateThread } from "../Common/Diag";
import PureReactTable from "../../../component-js/common/PureReactTable";
import { Button, Grid, Segment, Loader, Dimmer } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { formatDatetime } from "../../../utils/dateUtils";
import { createPDF } from "../../../utils/createPDF"
import { convertToBEDate } from "../../../utils/tsUtils";
import config from "../../../../config/config";

const blankSpace20 = "\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0"
const blankSpace15 = "\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0"

const CardDiagFormHistory = props => {
  const isMounted = useRef(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [cookies, setCookie] = useCookies([]);
  const [diagForm, setDiagForm] = useState({});
  const [selectDiagForm, setSelectDiagForm] = useState(0);
  const [publishDiagRule, setPublishDiagRule] = useState(null);
  const [thread, setThread] = useState([]);
  const [masterTriageLevel, setMasterTriageLevel] = useState([]);
  const [selectedTriageLevel, setSelectedTriageLeve] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 20;

  useEffect(() => {

    getDiagForm();
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    const getTriageLevelClassify = async () => {
      const [
        response,
        error,
        network
      ] = await props.controller.getTriageLevelClassify({
        apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken,
        divisionId: props.division ? props.division : cookies.division_id
      });

      if (isMounted.current) {
        if (response) {
          setMasterTriageLevel(response);
        }
      }
    };

    if (props.division || cookies.division_id) {
      getTriageLevelClassify();
    }
  }, [props.division, cookies.division_id])

  const getDiagForm = async () => {
    setDiagForm([])
    setThread([])

    let patientId = null;
    if (props.patientData && props.patientData.patient) {
      patientId = props.patientData.patient;
    } else if (
      props.match &&
      props.match.params &&
      props.match.params.patientId
    ) {
      patientId = props.match.params.patientId;
    }
    if (!patientId) {
      return;
    }
    setIsLoading(true);
    let [response, error, network] = await props.controller.getDiagForm({
      apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken,
      patient: patientId,
      division: props.division ? props.division : cookies.division_id,
      limit,
      offset: limit * (page - 1)
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (response) {
        setTotalPage(Math.ceil(response.total / limit));
        // setTotalDiagForm(response.total)
        setDiagForm(response.items.filter(item => item.finished));
      }
    }
  };

  useEffect(() => {
    if (Object.keys(diagForm).length > 0) {
      getPublish(selectDiagForm);
    }
  }, [diagForm]);

  useEffect(() => {
    if (Object.keys(diagForm).length > 0) {
      getPublish(selectDiagForm);
    }

    // Perpare Triage Level
    let prepareTriage =
      diagForm &&
      Object.keys(diagForm).length > 0 &&
      masterTriageLevel &&
      masterTriageLevel.classify &&
      masterTriageLevel.classify.length > 0 &&
      masterTriageLevel.classify.find(triage => {
        return triage.triage_level == diagForm[selectDiagForm].triage_level;
      });
    prepareTriage = prepareTriage ? prepareTriage : null;
    setSelectedTriageLeve(prepareTriage);
  }, [selectDiagForm]);

  const getPublish = async (selectDiagForm, isReturn = false) => {
    let answerForm =
      diagForm && diagForm.length > selectDiagForm - 1
        ? diagForm[selectDiagForm]
        : {};
    let answer = answerForm.answers ? JSON.parse(answerForm.answers) : {};
    let diagRuleId = answerForm.published_diag_rule;

    let [
      response,
      error,
      network
    ] = await props.controller.getPublishedDiagRule({
      apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken,
      diagRuleId: diagRuleId ? diagRuleId : null
    });

    if (response) {
      if (isMounted.current) {
        if (!isReturn) {
          setPublishDiagRule(response);
        }
        if (response) {
          let x = prepareForm(response);

          // Combine answer into Question
          for (var key in x) {
            // check if the property/key is defined in the object itself, not in parent
            if (x.hasOwnProperty(key)) {
              if (typeof answer[key] === "boolean") {
                x[key].selected = answer[key] ? true : false;
              } else {
                if (Object.keys(answer).length > 0) {
                  if (answer[key]) {
                    x[key].answer = answer[key].answer;
                    x[key].selected = answer[key].selected;
                    x[key].extra_finished = answer[key].extra_finished;
                  } else {
                    x[key].answer = "";
                    x[key].selected = "";
                    x[key].extra_finished = "";
                  }
                }

                if (
                  answer[key] &&
                  answer[key].extra_items &&
                  x[key] &&
                  x[key].extra_items
                ) {
                  x[key].extra_items.forEach(item => {
                    let z = answer[key].extra_items.find(ans => {
                      return ans.label == item.label;
                    });
                    if (z) {
                      item.value = z.value;
                    }
                  });
                }
              }
            }
          }

          let y = Object.values(x);
          var targetThread = [];
          targetThread = updateThread([], y, y[0]);

          if (isReturn) {
            return targetThread
          }
          setThread(targetThread);
        }
      }
    }
  };

  React.useEffect(() => {
    getDiagForm();
  }, [
    page,
    props.patientData && props.patientData.patient,
    props.match && props.match.params && props.match.params.patientId
  ]);

  const dummyFunc = () => () => { };

  const createPage = () => {
    let a = [];
    for (let i = 1; i < totalPage + 1; i++) {
      a.push(
        <Button
          style={{ margin: 5 }}
          color={page === i ? "green" : "blue"}
          compact
          content={i}
          onClick={() => setPage(i)}
          toggle={page === i}
        />
      );
    }
    return a;
  };

  const handleCreatePdf = async (index) => {
    const thread = await getPublish(index, true)

    console.log(index, thread, props.patientAllergyData)
    const shortLang = {
      "English": "EN",
      "ภาษาไทย": "TH"
    }
    const thaiToEng = {
      "ไม่มีปัญหาเลย": "Not difficult at all",
      "มีปัญหาบ้าง": 'Somewhat difficult',
      "มีปัญหามาก": "Very difficult",
      "มีปัญหามากที่สุด": "Extremely difficult"
    }
    const score = [0, 1, 2, 3]
    const patient: any = props.patientData || {}
    const patientAllergy = props.patientAllergyData || {}
    const topicList: any[] = thread[1].extra_items
    const answer: any = {}
    const sum = {
      answerMentalSum0: 0,
      answerMentalSum1: 0,
      answerMentalSum2: 0,
      answerMentalSum3: 0
    }
    const total = {
      answerMentalTotal0: 0,
      answerMentalTotal1: 0,
      answerMentalTotal2: 0,
      answerMentalTotal3: 0
    }
    const language: 'TH' | "EN" = shortLang[thread[0].answer]

    for (const [index, list] of topicList.entries()) {
      const answerIndex = list.options.indexOf(list.value)
      const keySum = `answerMentalSum${answerIndex}`

      answer[`answerMentalno${index + 1}`] = answerIndex
      sum[keySum] += 1
      total[`answerMentalTotal${answerIndex}`] = sum[keySum] * score[answerIndex]
    }

    const genPdfData = {
      language, // "EN"
      patientName: `${patient.first_name} ${patient.last_name}`,
      nationality: patient.extra_data.nationality?.split(" ")?.[1]?.replace(/\(|\)/g, ""),
      room: "",
      hn: patient.hn,
      physician: "",
      visitDate: "",
      department: "",
      birthDate: patient.birthdate
        ? convertToBEDate({ date: moment(patient.birthdate, ["DD/MM/YYYY", "YYYY-MM-DD"]).format("DD/MM/YYYY") })
        : "",
      age: patient.age,
      sex: patient.extra_data.gender.split(" ")[0],
      allergies: patientAllergy?.note_dict?.allergy || "",
      sideEffect: patientAllergy?.note_dict?.side_effect || "",
      ...answer,
      answerMentalSum0: `${sum.answerMentalSum0} x 0 = ${total.answerMentalTotal0}`,
      answerMentalSum1: `${sum.answerMentalSum1} x 1 = ${total.answerMentalTotal1}`,
      answerMentalSum2: `${sum.answerMentalSum2} x 2 = ${total.answerMentalTotal2}`,
      answerMentalSum3: `${sum.answerMentalSum3} x 3 = ${total.answerMentalTotal3}`,
      answerMentalTotal: total.answerMentalTotal0 + total.answerMentalTotal1 + total.answerMentalTotal2 + total.answerMentalTotal3,
      answerDifficult: language === "EN" ? thread[2].node.answer : thaiToEng[thread[2].node.answer],

      // unableToAccess: false,
      // consultPsychiatrist: false,
      // consultPsychologist: true,
      consultPsychologistDate: blankSpace20,
      consultPsychologistTime: blankSpace15,
      // notConsultPsy: false,
      // registeredNurse: "",
      registeredNurseDate: blankSpace20,
      registeredNurseTime: blankSpace15,
      scanBy: "",
    }
    console.log(genPdfData)
    createPDF(genPdfData)
  }

  return (
    <CardLayout
      titleText="ประวัติการทำแบบคัดกรอง"
      headerColor={props.headerColor}
      closeable={true}
      toggleable={false}
      hideHeaderIcon={props.hideHeaderIcon}
      dividerStyle={props.dividerStyle}
      loading={isLoading}
      onClose={props.hideCallback}
      defaultBackground={true}
      style={{ backgroundColor: "defaultBackground", ...props.cardStyle }}
    >
      <div
        style={{ display: "flex", flowDirection: "column", justifyContent: "" }}
      >
        <div
          style={{
            flexGrow: 4,
            flexBasis: 0,
            height: "80vh",
            marginRight: "3px"
          }}
        >
          <Segment style={{ height: "80vh", overflow: "auto" }}>
            {diagForm && Array.isArray(diagForm) && diagForm.length > 0 ? (
              diagForm.map((item, index) => (
                <Segment
                  style={{
                    backgroundColor:
                      selectDiagForm === index ? "#d6fffe" : "white",
                    marginTop: "2px",
                    marginBottom: "2px",
                    border: "0px"
                  }}
                  key={index}
                  onClick={() => {
                    setSelectDiagForm(index);
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ flexGrow: 3, flexBasis: 0 }}>
                      <div
                        style={{
                          color: "#828282",
                          fontWeight: "bold",
                          fontSize: "16px"
                        }}
                      >
                        {item.diag_rule_name}
                      </div>
                      <br />
                      <div style={{ color: "#828282", fontSize: "14px" }}>
                        {item.updated
                          ? formatDatetime(item.updated)
                          : "unknown"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        flexBasis: 0,
                        justifyContent: "flex-end",
                        alignItems: "center"
                      }}
                    >
                      <div style={{ color: "#828282", fontSize: "14px" }}>
                        {false}
                        {masterTriageLevel &&
                          item.triage_level &&
                          masterTriageLevel.classify &&
                          masterTriageLevel.classify.length > 0 &&
                          masterTriageLevel.classify.find(triage => {
                            return triage.triage_level == item.triage_level;
                          }) &&
                          masterTriageLevel.classify.find(triage => {
                            return triage.triage_level == item.triage_level;
                          }).description}
                      </div>
                      {config.PDF_PRINT_DIAGFORM_ID.includes(item.published_diag_rule) &&
                        <div style={{ position: "absolute", bottom: "14px" }}>
                          <Button color="teal" size="mini" onClick={() => handleCreatePdf(index)}>PDF</Button>
                        </div>}
                    </div>
                  </div>
                </Segment>
              ))
            ) : (
              <Dimmer active={isLoading} inverted>
                <Loader inverted>Loading</Loader>
              </Dimmer>
            )}
          </Segment>
        </div>
        <div style={{ flexGrow: 6, flexBasis: 0, height: "80vh" }}>
          <Segment
            style={{ height: "80vh", overflow: "auto", marginRight: "3px" }}
          >
            <div>
              <div
                style={{
                  color: "#828282",
                  fontSize: "20px",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                {"ผลการคัดกรอง : "}
                {selectedTriageLevel && selectedTriageLevel.description
                  ? selectedTriageLevel.description
                  : "N/A"}
              </div>
              <div style={{ marginTop: "5px" }}>
                {thread.length > 0 ? (
                  <QuestionSection
                    threadQuestion={thread}
                    selectedAnswer={dummyFunc}
                  />
                ) : null}
              </div>
            </div>
            {/* {thread.length > 0 ? (
              <QuestionSection
                threadQuestion={thread}
                selectedAnswer={dummyFunc}
              />
            ) : null} */}
            {/* {publishDiagRule ? publishDiagRule.content : null} */}
          </Segment>
        </div>
      </div>
      <div style={{ textAlign: "right" }}>{createPage()}</div>
      {/* <Grid style={{ height: "550px", padding: "2%" }}>
      <Grid.Column width={6} style={{ overflow: 'auto', maxHeight: 200, backgroundColor: "white", border: "1px solid grey", padding: 10 }}>
       
      </Grid.Column>
      <Grid.Column width={10} style={{ backgroundColor: "white", border: "1px solid grey" }}>
        aweawewaewaqe
      </Grid.Column>



    </Grid> */}
    </CardLayout>
  );
};

CardDiagFormHistory.defaultProps = {
  hideCallback: () => { },
  controller: null,
  hideHeaderIcon: false,
  headerColor: "red",
  dividerStyle: {},
  cardStyle: {},
  patientAllergyData: {}
};

CardDiagFormHistory.propTypes = {
  hideCallback: PropTypes.func,
  controller: PropTypes.object,
  hideHeaderIcon: PropTypes.bool,
  headerColor: PropTypes.string,
  dividerStyle: PropTypes.object,
  cardStyle: PropTypes.object,
  patientAllergyData: PropTypes.object
};

export default React.memo(CardDiagFormHistory);
