import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Menu,
  Modal,
  Icon,
  Form,
  Dropdown
} from "semantic-ui-react";
import ChatTable from "./BILChatTable";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TimeNurse from "../react-lib/apps/QUE/TimeNurse";
import TimeDoctor from "../react-lib/apps/QUE/TimeDoctor";
import TimeDoctorEdit from "../react-lib/apps/QUE/TimeDoctorEdit";
import DoctorScheduleView from "../react-lib/apps/QUE/DoctorScheduleView";
import ModSelectAppointment from "../react-lib/apps/IsHealth/Common/ModSelectAppointment";
import Dashboard from "../react-lib/apps/IsHealth/Dashboard/Dashboard";
import config from "../config/config";
import AppointmentDashboard from "../react-lib/apps/IsHealth/APP/AppointmentDashboard";

const PAGE = {
  CHAT: "/Chat",
  SCHEDULE: "/Schedule",
  MY_SCHEDULE: "/MySchedule",
  DASHBOARD: "/Dashboard",
  APPOINTMENT_DASHBOARD: "/AppointmentDashboard"
};

interface MainScreenProps {}

const MainScreen = React.forwardRef((props: MainScreenProps, ref) => {
  const [currentPage, setCurrentPage] = React.useState("");
  const [
    openModChooseAppointment,
    setOpenModChooseAppointment
  ] = React.useState(false);
  const [patientAppointmentId, setPatientAppointmentId] = React.useState(null);

  const chatTable = React.useRef();
  const dashboardRef = React.useRef();
  const history = useHistory();

  React.useImperativeHandle(ref, () => ({
    chatTable: chatTable.current,
    dashboard: dashboardRef.current,
  }));

  React.useEffect(() => {
    if(currentPage !== PAGE.DASHBOARD){
      props.onReconnectWs()
    }
  }, [currentPage])

  React.useEffect(() => {
    if (history.location.pathname.includes(PAGE.CHAT)) {
      setCurrentPage(PAGE.CHAT);
    } else if (history.location.pathname.includes(PAGE.SCHEDULE)) {
      setCurrentPage(PAGE.SCHEDULE);
    } else if (history.location.pathname.includes(PAGE.MY_SCHEDULE)) {
      setCurrentPage(PAGE.MY_SCHEDULE);
    } else if (history.location.pathname.includes(PAGE.DASHBOARD)) {
      setCurrentPage(PAGE.DASHBOARD);
    } else if (history.location.pathname.includes(PAGE.APPOINTMENT_DASHBOARD)) {
      setCurrentPage(PAGE.APPOINTMENT_DASHBOARD);
    } else {
      history.push("/Chat");
    }
  }, [history.location]);

  React.useEffect(() => {
    props.onGetDivision();
  }, []);

  const handleCreateAppointment = ({ contentPayload, appointmentId } = {}) => {
    let id = contentPayload
      ? contentPayload.patient_appointment
      : appointmentId;
    setPatientAppointmentId(id);
    setOpenModChooseAppointment(true);
  };

  const handleNavigationMessage = ({
    content = "",
    contentPayload = null
  } = {}) => {
    if (content.includes("{ตารางออกตรวจผู้ให้คำปรึกษา}")) {
      let contentArr = content.split("{ตารางออกตรวจผู้ให้คำปรึกษา}");
      return (
        <div>
          {contentArr.map((item, index) => {
            if (index !== contentArr.length - 1) {
              return (
                <>
                  {item}
                  <span
                    className="textButton"
                    onClick={() => handleCreateAppointment({ contentPayload })}
                  >
                    ตารางออกตรวจผู้ให้คำปรึกษา
                  </span>
                </>
              );
            }
          })}
        </div>
      );
    } else {
      return content;
    }
  };

  return (
    <div className="MainScreen">
      <ModSelectAppointment
        controller={props.queueController}
        open={openModChooseAppointment}
        onClose={() => {
          setPatientAppointmentId(null);
          setOpenModChooseAppointment(false);
        }}
        patientAppointmentId={patientAppointmentId}
        division={props.division}
      />
      <Menu secondary>
        {props.getLeftMenuItem()}
        {props.getDivisionMenuItem()}
        {props.getRightMenuItem()}
      </Menu>
      <Grid>
        <Grid.Column className="tab">
          {config.DASHBOARD && (
            <Button
              className="tabButton"
              icon="pie chart"
              active={currentPage === PAGE.DASHBOARD}
              onClick={() => history.push("/Dashboard/")}
            />
          )}
          {config.DASHBOARD && (
            <Button
              className="tabButton"
              icon="users"
              active={currentPage === PAGE.APPOINTMENT_DASHBOARD}
              onClick={() => history.push("/AppointmentDashboard/")}
            />
          )}
          <Button
            className="tabButton"
            icon="chat"
            active={currentPage === PAGE.CHAT}
            onClick={() => history.push("/Chat/")}
          />
        </Grid.Column>
        <Grid.Column className="page">
          <Route
            exact
            path="/Chat/:encounterId?/:chatChannelId?/"
            render={chatProps => (
              <ChatTable
                {...props}
                {...chatProps}
                ref={chatTable}
                // ref={ref => (this.chatTable = ref)}
                allowCreateAppoint={true}
                controller={props.chatController}
                diagFormController={props.diagFormController}
                patientPanelController={props.patientPanelController}
                nurseNoteController={props.nurseNoteController}
                progressNoteController={props.progressNoteController}
                classifyController={props.classifyController}
                apiToken={props.apiToken}
                onGetDivision={props.loadMydivision}
                division={props.division}
                userId={props.userId}
                fullname={props.fullname}
                username={props.username}
                finishedTriageLevel={props.finishedTriageLevel}
                onNavigationMessage={handleNavigationMessage}
                onEditAppointment={handleCreateAppointment}
                isPatient={false}
              />
            )}
          />
          {config.DASHBOARD && (
            <Route
              exact
              path="/AppointmentDashboard/"
              render={appProps => (
                <AppointmentDashboard
                  {...props}
                  {...appProps}
                  controller={props.appointmentDashboardController}
                />
              )}
            />
          )}
          {config.DASHBOARD && (
            <Route
              exact
              path="/Dashboard/"
              render={appProps => (
                <Dashboard
                  {...appProps}
                  ref={dashboardRef}
                  apiToken={props.apiToken}
                  division={props.division}
                  controller={props.dashboardController}
                />
              )}
            />
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
});

MainScreen.defaultProps = {
  diagFormController: {},
  chatController: {},
  patientPanelController: {},
  nurseNoteController: {},
  progressNoteController: {},
  classifyController: {},
  onGetDivision: () => {},
  finishedTriageLevel: [],
  queueController: {}
};

MainScreen.propTypes = {
  diagFormController: PropTypes.object,
  chatController: PropTypes.object,
  patientPanelController: PropTypes.object,
  nurseNoteController: PropTypes.object,
  progressNoteController: PropTypes.object,
  classifyController: PropTypes.object,
  apiToken: PropTypes.string,
  onGetDivision: PropTypes.func,
  division: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullname: PropTypes.string,
  username: PropTypes.string,
  finishedTriageLevel: PropTypes.array,
  queueController: PropTypes.object
};

export default React.memo(MainScreen);
