// eslint-disable @typescript-eslint/ban-ts-comment
// @ts-ignore

import React from "react";
import PropTypes from "prop-types";
import * as Common from "../common";
import * as Util from "../../utils";
import {
  Container,
  Grid,
  Segment,
  Form,
  Button,
  Input,
  Checkbox
} from "semantic-ui-react";
import {
  SearchBox,
  ComboBox,
  DateTextBox,
  ModAuthen as ModSecretAuthen,
  ModInfo as ModInfoSuccess
} from "../common";
import PureReactTable, { alignCenter } from "../common/PureReactTable";
import ChromePrint from "../../../_qmljs/Common/ChromePrint";
import Zoom from "../../../_qmljs/Common/Zoom";
import { printURL } from "../../utils/printURL";

const CardScannedDocument = React.forwardRef((props: any, ref) => {
  const isMounted = React.useRef(false);

  const [nationalitiesChoices, setNationalitiesChoices] = React.useState([]);
  const [currentHNPage, setCurrentHNPage] = React.useState(1);
  const [numberOfHNPage, setNumberOfHNPage] = React.useState(1);
  const [patientList, setPatientList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [image, setImage] = React.useState("");

  const [scannedDocList, setScannedDocList] = React.useState([]);

  const [searchBoxOptions, setSearchBoxOptions] = React.useState([]);
  const [defaultSearchBoxId, setDefaultSearchBoxId] = React.useState(null);
  const [defaultSearchBoxText, setDefaultSearchBoxText] = React.useState("");
  const [documentCategoryOptions, setDocumentCategoryOptions] = React.useState(
    []
  );
  const [documentTypeOptions, setDocumentTypeOptions] = React.useState([]);
  const [divisionOptions, setDivisionOptions] = React.useState([]);

  const [docNumber, setDocNumber] = React.useState("");
  const [startDoc, setStartDoc] = React.useState("");
  const [endDoc, setEndDoc] = React.useState("");
  const [startScan, setStartScan] = React.useState("");
  const [endScan, setEndScan] = React.useState("");
  const [startExp, setStartExp] = React.useState("");
  const [endExp, setEndExp] = React.useState("");

  const [checkedAll, setCheckedAll] = React.useState(false);

  const [scannedDocLimit, setScannedDocLimit] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [numberOfPage, setNumberOfPage] = React.useState(0);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const [openModSecretAuthen, setOpenModSecretAuthen] = React.useState(false);
  const [openModInfoSuccess, setOpenModInfoSuccess] = React.useState(false);
  const [openModEditDoc, setOpenModEditDoc] = React.useState(false);

  const [cancelDocError, setCancelDocError] = React.useState(null)
  const [editDocError, setEditDocError] = React.useState(null)

  const [cboLoading, setCboLoading] = React.useState(false);

  const hnRef = React.useRef();
  const enRef = React.useRef();
  const docCategoryRef = React.useRef();
  const docTypeRef = React.useRef();
  const ownerDivRef = React.useRef();
  const scanDivRef = React.useRef();
  const scanUserRef = React.useRef();
  const doctorRef = React.useRef();
  const modSecretAuthenRef = React.useRef();
  const zoomRef = React.useRef();
  const modEditDocRef = React.useRef();

  React.useEffect(() => {
    //! MUST not be move below CDM UNLESS calling the func at the first render is desired
    //! Currently, it will be called only if users change the page limit
    //! The encounterId is not yet ready at the first render, after encounterId is fetch,
    //! handleSearchDocument() will be called by the another React.useEffect below
    if (isMounted.current) {
      if (currentPage === 0) {
        handleSearchDocument();
      } else {
        setCurrentPage(0);
      }
    }
  }, [scannedDocLimit]);

  React.useEffect(() => {
    if (isMounted.current) {
      handleSearchDocument();
    }
  }, [currentPage]);

  React.useEffect(() => {
    //! ALL React.useEffect above this func are expected to not be called during the first render
    //* ComponentDidMount -- All func declare here is expected to be called only once,
    //*                      If it is called more than once, please move it out of useEffect

    //! set isMounted.current here since some useEffect are not expected to function at the first render
    isMounted.current = true;

    const getDocumentCategory = async () => {
      setCboLoading(true);
      const [data, error] = await props.controller.getDocumentCategory({
        limit: "99999",
        division: 'all'
      });
      if (isMounted.current) {
        setCboLoading(false);
        if (data) {
          setDocumentCategoryOptions(data);
        } else {
          setDocumentCategoryOptions([]);
        }
      }
    };

    const getDivision = async () => {
      setCboLoading(true);
      const [data, error] = await props.controller.getDivision({
        limit: "99999"
      });
      if (isMounted.current) {
        setCboLoading(false);
        if (data) {
          setDivisionOptions(data);
        } else {
          setDivisionOptions([]);
        }
      }
    };

    getDocumentCategory();
    getDivision();

    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    //! will get new Scanned Doc every time encounterId change
    const defaultGetScannedDoc = async () => {
      if (props.patientInfo.encounter_id) {
        await defaultGetEncounter();
        if (!isMounted.current) {
          return;
        }
        handleSearchDocument();
      } else {
        handleSearchDocument();
      }
    };

    defaultGetScannedDoc();
  }, [props.patientInfo]);

  React.useEffect(() => {
    const checkSecretAll = () => {
      let arr = scannedDocList.filter((item: any) => item.is_secret);
      if (arr.length === scannedDocList.length && scannedDocList.length > 0) {
        setCheckedAll(true);
      } else {
        setCheckedAll(false);
      }
    };
    checkSecretAll();
  }, [scannedDocList]);

  React.useEffect(() => {
    setCancelDocError(null)
    setEditDocError(null)
  }, [openModEditDoc])

  const getDocumentType = async ({ category, name }:any = {}) => {
    (docTypeRef as any).current.clear();
    setCboLoading(true);
    const [data, error] = await props.controller.getDocumentType({
      limit: "99999",
      category,
      name
    });
    if (isMounted.current) {
      setCboLoading(false);
      if (data) {
        setDocumentTypeOptions(data);
      } else {
        setDocumentTypeOptions([]);
      }
    }
    return [data, error];
  };

  const getNationality = async () => {
    const [data, error] = await props.controller.getNationalities();
    if (isMounted.current) {
      if (data) {
        setNationalitiesChoices(data);
      } else {
        setNationalitiesChoices([]);
      }
    }
  };

  const getPatientList = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage
  }: any = {}) => {
    let limit = 40;
    let page = patientCurrentPage ? patientCurrentPage : currentHNPage;
    setIsLoading(true);
    setCurrentHNPage(page);
    const [data, error] = await props.controller.getSearchAllPatient({
      hn,
      fullName,
      citizenId,
      nationality,
      is_old_name_search: true,
      limit,
      offset: (page - 1) * limit
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        setPatientList(data.items);
        setNumberOfHNPage(parseInt(data.total) / limit + 1);
      } else {
        setPatientList([]);
        setNumberOfHNPage(1);
      }
    }
    return [data, error];
  };

  const handlePaginationChange = async ({
    hn,
    fullName,
    citizenId,
    nationality,
    patientCurrentPage
  }: any = {}) => {
    setCurrentHNPage(patientCurrentPage);
    getPatientList({
      hn,
      fullName,
      citizenId,
      nationality,
      patientCurrentPage
    });
  };

  const defaultGetEncounter = async () => {
    const [data, error] = await getEncounterSearch({
      encounterId: props.patientInfo.encounter_id
    } as any);
    if (isMounted.current) {
      if (data) {
        setSearchBoxOptions(data);
        setDefaultSearchBoxId(data[0].id);
        setDefaultSearchBoxText(data[0].number_date);
      }
    }
    return [data, error];
  };

  const getEncounterSearch = async ({ searchText, encounterId }: any = {}) => {

    let hnInput;
    let patientId; 

    if (hnRef && hnRef.current) {
      hnInput = (hnRef as any).current.value()
      patientId = (hnRef as any).current.getPatientId()
      if(hnInput === props.patientInfo.hn){
        patientId = props.patientInfo.id
      }
      
      const [data, error] = await props.controller.getEncounterSearch({
        search: searchText,
        encounterId,
        patientId
      });
  
      if (!error) {
        return [data.items, error];
      } else {
        return [null, error]
      }
    } else {
      return [ null, "hnRef are null"]
    }
  };

  const getUsersSearch = async ({ searchText }: any = {}) => {
    const [data, error] = await props.controller.getUsers({
      fullName: searchText
    });
    return [data, error];
  };

  const getDoctorSearch = async ({ searchText }: any = {}) => {
    const [data, error] = await props.controller.getDoctor({
      search: searchText
    });
    return [data, error];
  };

  const handleSearchDocument = async () => {
    setError(null);
    setIsLoading(true);

    let patientHn = null
    let encounterId = null
    let documentType = null
    let scanDivision = null
    let scanUser = null
    let ownerDivision = null
    let encounterDoctor = null

    if (hnRef && hnRef.current) {
      patientHn = (hnRef as any).current.value()
    }
    if (enRef && enRef.current) {
      encounterId = (enRef as any).current.getId()
    }

    if (docTypeRef && docTypeRef.current) {
      documentType = (docTypeRef as any).current.getValue()
    }

    if (scanDivRef && scanDivRef.current) {
      scanDivision = (scanDivRef as any).current.getValue()
    }

    if (scanUserRef && scanUserRef.current) {
      scanUser = (scanUserRef as any).current.getId()
    }

    if (ownerDivRef && ownerDivRef.current) {
      ownerDivision = (ownerDivRef as any).current.getValue()
    }

    if (doctorRef && doctorRef.current) {
      encounterDoctor = (doctorRef as any).current.getId()
    }



    const [data, error] = await props.controller.getScannedDocument({
      patientHn: patientHn,
      encounterId: encounterId,
      documentType: documentType,
      documentNo: docNumber,
      scanDivision: scanDivision,
      scanUser: scanUser,
      ownerDivision: ownerDivision,
      encounterDoctor: encounterDoctor,
      docFromDate: startDoc,
      docToDate: endDoc,
      scanFromDate: startScan,
      scanToDate: endScan,
      expiryFromDate: startExp,
      expiryToDate: endExp,
      limit: scannedDocLimit,
      offset: scannedDocLimit * currentPage
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        setNumberOfPage(parseInt(data.total) / scannedDocLimit);
        setScannedDocList(data.items);
      } else {
        setError(error);
        setScannedDocList([]);
      }
    }
    
  };

  const handleUpdateSecret = async ({ username, password }: any = {}) => {
    setIsLoading(true);
    setError(null);
    const [data, error] = await props.controller.putScannedDocUpdateSecret({
      username,
      password,
      items: scannedDocList
    });
    if (isMounted.current) {
      if (error) {
        setError(error);
      } else {
        setOpenModSecretAuthen(false);
        (modSecretAuthenRef as any).current.clear();
        setOpenModInfoSuccess(true);
        handleSearchDocument();
      }
      setIsLoading(false);
    }
    return [data, error];
  };

  const handleCancelDocument = async ({
    username,
    password,
    cancelNote,
    scanDocId
  }: any = {}) => {
    setIsLoading(true);
    setCancelDocError(null);
    const [data, error] = await props.controller.patchScannedDocument({
      username,
      password,
      cancelNote,
      scanDocId,
      active: false
    });
    if (isMounted.current) {
      if (error) {
        setCancelDocError(error);
      } else {
        setOpenModEditDoc(false);
        handleSearchDocument();
      }
      setIsLoading(false);
    }
    return [data, error];
  };

  const handleEditDocument = async ({
    username,
    password,
    scanDocId,
    documentType,
    documentNo,
    version,
    ownerDivision,
    documentDate,
    expiryDate,
    remark
  }: any = {}) => {
    setIsLoading(true);
    setEditDocError(null);
    const [data, error] = await props.controller.patchScannedDocument({
      username,
      password,
      scanDocId,
      documentType,
      documentNo,
      version,
      ownerDivision,
      documentDate,
      expiryDate,
      remark
    });
    if (isMounted.current) {
      if (error) {
        setEditDocError(error);
      } else {
        setOpenModEditDoc(false);
        handleSearchDocument();
      }
      setIsLoading(false);
    }
    return [data, error];
  };

  const generatePagination = () => {
    let btnArr = [];
    for (let i = 0; i < numberOfPage; i++) {
      let color = null;
      if (currentPage === i) {
        color = "blue";
      }
      btnArr.push(
        <Button
          onClick={() => setCurrentPage(i)}
          key={"btnPage" + i}
          testid={"btnPage" + i}
          icon
          content={i + 1}
          color={color as any}
        />
      );
    }
    return btnArr;
  };

  const handleCheckedAll = (e: any, { checked }: any) => {
    let newArr = [...scannedDocList];
    for (let item of newArr) {
      if (checked) {
        (item as any).is_secret = true;
      } else {
        (item as any).is_secret = false;
      }
    }
    setScannedDocList(newArr);
  };

  const handleCheckIsSecret = ({ row, checked }: any = {}) => {
    let index = scannedDocList.findIndex((item: any) => item.id === row.original.id);
    let newArr = [...scannedDocList];
    (newArr[index] as any).is_secret = checked;
    setScannedDocList(newArr);
  };


  console.log("CardScannedDocument props:", props)
  return (
    <Common.CardLayout
      closeable={props.closeable}
      toggleable={props.toggleable}
      titleText="Scanned Documents"
      headerColor="blue"
      loading={isLoading}
      onClose={props.onClose}
    >
      <ModInfoSuccess
        titleColor="green"
        titleName="บันทึกสำเร็จ"
        btnText="ตกลง"
        open={openModInfoSuccess}
        onApprove={() => setOpenModInfoSuccess(false)}
      />
      <ModSecretAuthen
        ref={modSecretAuthenRef}
        open={openModSecretAuthen}
        titleName="ปกปิดเอกสาร"
        approveButtonText="ตกลง"
        denyButtonText="ปิด"
        onApprove={handleUpdateSecret}
        onCloseWithDimmerClick={() => {
          setOpenModSecretAuthen(false);
          (modSecretAuthenRef as any).current.clear();
        }}
        onDeny={() => {
          setOpenModSecretAuthen(false);
          (modSecretAuthenRef as any).current.clear();
        }}
        loading={isLoading}
        error={error}
      />
      <Zoom ref={zoomRef as any} />
      <Common.ModEditDocument
        ref={modEditDocRef}
        open={openModEditDoc}
        onClose={() => setOpenModEditDoc(false)}
        data={selectedRow}
        onGetDocumentTypeOptions={({ searchText }: any = {}) =>
          getDocumentType({ name: searchText })
        }
        divisionOptions={divisionOptions}
        onCancelApprove={handleCancelDocument}
        onEditApprove={handleEditDocument}
        cancelDocError={cancelDocError}
        editDocError={editDocError}
        loading={isLoading}
      />
      <Common.ErrorMessage error={error} />
      <Common.PatientIdentification patientInfo={props.patientInfo} />
      <Container fluid>
        <Grid columns={2}>
          <Grid.Column>
            <Segment color="grey" inverted style={styles.segment}>
              <img
                // @ts-ignore
                testid="imgScanned"
                style={styles.image}
                src={image}
                onClick={(e) => {
                  (zoomRef as any).current.qml.show(image, e);
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field width={6}>
                  <label>HN</label>
                  <Common.PatientSearchBox
                    // @ts-ignore
                    testid="sbxHN"
                    ref={hnRef}
                    patientList={patientList}
                    getNationality={getNationality}
                    getPatientList={getPatientList}
                    nationalitiesList={nationalitiesChoices}
                    patientListLoading={isLoading}
                    numberOfPage={numberOfHNPage}
                    currentPage={currentHNPage}
                    initialValue={props.patientInfo.hn}
                    onPatientListPaginationChange={handlePaginationChange}
                  />
                </Form.Field>
                <Form.Field width={6}>
                  <label>Encounter</label>
                  <SearchBox
                    ref={enRef}
                    testid="sbxEncounter"
                    onGetSearchOptions={getEncounterSearch}
                    defaultOptions={searchBoxOptions}
                    defaultId={defaultSearchBoxId}
                    defaultText={defaultSearchBoxText}
                    textField="number_date"
                  />
                </Form.Field>
                <Form.Field width={4}>
                  <label>&ensp;</label>
                  <Button
                    testid="btnSearch"
                    content="ค้นหา"
                    color="blue"
                    fluid
                    onClick={handleSearchDocument}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={10}>
                  <label>ประเภทเอกสาร</label>
                  <ComboBox
                    testid="cboDocCategory"
                    ref={docCategoryRef}
                    fluid
                    clearable
                    placeholder="แสดงทุกประเภท"
                    options={documentCategoryOptions}
                    onChange={(e: any, { value }: any) =>
                      getDocumentType({ category: value })
                    }
                    loading={cboLoading}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={10}>
                  <label>ชื่อเอกสาร</label>
                  <ComboBox
                    testid="cboDocType"
                    ref={docTypeRef}
                    fluid
                    clearable
                    placeholder="แสดงทุกประเภท"
                    options={documentTypeOptions}
                    loading={cboLoading}
                  />
                </Form.Field>
                <Form.Field width={6}>
                  <label>เลขที่เอกสาร</label>
                  <Input
                    testid="txtDocNo"
                    value={docNumber}
                    onChange={(e) => setDocNumber(e.target.value)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={8}>
                  <label>แผนกที่สแกนเอกสาร</label>
                  <ComboBox
                    testid="cboScanDiv"
                    ref={scanDivRef}
                    fluid
                    placeholder="แสดงทุกแผนกที่สแกน"
                    clearable
                    loading={cboLoading}
                    options={divisionOptions}
                  />
                </Form.Field>
                <Form.Field width={8}>
                  <label>ผู้สแกนเอกสาร</label>
                  <SearchBox
                    ref={scanUserRef}
                    testid="sbxUsers"
                    onGetSearchOptions={getUsersSearch}
                    textField="full_name"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={8}>
                  <label>แผนกที่เจ้าของเอกสาร</label>
                  <ComboBox
                    testid="cboOwnerDiv"
                    ref={ownerDivRef}
                    fluid
                    placeholder="แสดงทุกแผนกที่สแกน"
                    // ref={ownerDivRef}
                    clearable
                    loading={cboLoading}
                    options={divisionOptions}
                  />
                </Form.Field>
                <Form.Field width={8}>
                  <label>แพทย์</label>
                  <SearchBox
                    ref={doctorRef}
                    testid="sbxDoctor"
                    onGetSearchOptions={getDoctorSearch}
                    textField="name_code"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={5} label="วันที่ของเอกสารสแกน" />
                <Form.Field width={5}>
                  <DateTextBox
                    testid="dtbStartDoc"
                    value={startDoc}
                    onChange={(date: any) => setStartDoc(date)}
                  />
                </Form.Field>
                <Form.Field width={1} label="ถึง" />
                <Form.Field width={5}>
                  <DateTextBox
                    testid="dtbEndDoc"
                    value={endDoc}
                    onChange={(date: any) => setEndDoc(date)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={5} label="วันที่สแกนเอกสาร" />
                <Form.Field width={5}>
                  <DateTextBox
                    testid="dtbStartScan"
                    value={startScan}
                    onChange={(date: any) => setStartScan(date)}
                  />
                </Form.Field>
                <Form.Field width={1} label="ถึง" />
                <Form.Field width={5}>
                  <DateTextBox
                    testid="dtbEndScan"
                    value={endScan}
                    onChange={(date: any) => setEndScan(date)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field width={5} label="วันที่หมดอายุของเอกสาร" />
                <Form.Field width={5}>
                  <DateTextBox
                    testid="dtbStartExp"
                    value={startExp}
                    onChange={(date: any) => setStartExp(date)}
                  />
                </Form.Field>
                <Form.Field width={1} label="ถึง" />
                <Form.Field width={5}>
                  <DateTextBox
                    testid="dtEndExp"
                    value={endExp}
                    onChange={(date: any) => setEndExp(date)}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
            <PureReactTable
              testid="docTable"
              className=""
              selectedClassName="blueSelectedRow"
              showPagination={false}
              showPageSizeOptions={false}
              minRows={0}
              style={styles.docTable}
              data={scannedDocList}
              onSelect={(originalRow: any) => {
                setSelectedRow(originalRow);
                setImage(originalRow.document_image);
              }}
              selectedRow={selectedRow}
              columns={[
                {
                  Header: "no",
                  accessor: "no",
                  width: 50,
                  show: props.readOnly,
                },
                {
                  Header: "",
                  accessor: "checked",
                  show: !props.readOnly,
                },
                {
                  Header: "วันที่",
                  accessor: "scan_date",
                },
                {
                  Header: "หมายเลขเอกสาร",
                  accessor: "document_no",
                  show: !props.readOnly,
                },
                {
                  Header: "ประเภทเอกสาร",
                  accessor: "document_type_name",
                  width: 300,
                },
                {
                  Header: "การปกปิด",
                  accessor: "is_secret",
                  Cell: (row: any) => {
                    return alignCenter(
                      <Checkbox
                        checked={row.value}
                        onChange={(e, { checked }) =>
                          handleCheckIsSecret({ row, checked })
                        }
                      />
                    );
                  },
                },
                {
                  Header: "พิมพ์",
                  accessor: "_print",
                  Cell: (row: any) => {
                    return alignCenter(
                      <Button
                        color="teal"
                        icon="print"
                        onClick={() => {
                          printURL(row.original.document_image);
                        }}
                      />
                    );
                  },
                },
                {
                  Header: "แก้ไข",
                  accessor: "_edit",
                  minWidth: 50,
                  Cell: (row: any) => {
                    return alignCenter(
                      <Button
                        color="yellow"
                        icon="pencil"
                        onClick={() => {
                          setOpenModEditDoc(true);
                        }}
                      />
                    );
                  },
                },
              ]}
            />
            <br />
            <Form>
              <Form.Group inline>
                <Form.Field width={2}>
                  <ComboBox
                    options={pageOptions}
                    value={scannedDocLimit}
                    onChange={(e: any, { value }: any) => setScannedDocLimit(value)}
                  />
                </Form.Field>
                {generatePagination()}
              </Form.Group>
              <Form.Group inline className="rightAlign">
                <Form.Field>
                  <Checkbox checked={checkedAll} onChange={handleCheckedAll} />
                </Form.Field>
                <Form.Field label="ปกปิดเอกสาร" />
                <Form.Field>
                  <Button
                    color="yellow"
                    content="ปกปิดเอกสาร"
                    onClick={() => setOpenModSecretAuthen(true)}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid>
      </Container>
    </Common.CardLayout>
  );
});

const pageOptions = [
  {
    key: 10,
    value: 10,
    text: 10
  },
  {
    key: 20,
    value: 20,
    text: 20
  },
  {
    key: 30,
    value: 30,
    text: 30
  },
  {
    key: 40,
    value: 40,
    text: 40
  },
  {
    key: 50,
    value: 50,
    text: 50
  },
  {
    key: 100,
    value: 100,
    text: 100
  }
];

const styles = {
  docTable: {
    height: "250px"
  },
  segment: {
    height: "100%",
    overflowY: "auto",
    display: "flex",
    justifyContent: "center"
  },
  image: { cursor: "zoom-in", height: "500px" }
};

CardScannedDocument.propTypes = {
  patientData: PropTypes.object,
  patientInfo: PropTypes.object,
  controller: PropTypes.object,
  readOnly: PropTypes.bool,
  onClose: PropTypes.func,
  closeable: PropTypes.bool,
  toggleable: PropTypes.bool,
};

CardScannedDocument.defaultProps = {
  patientData: {},
  patientInfo: {},
  controller: {},
  readOnly: true,
  django: {},
  onClose: () => {},
  closeable: true,
  toggleable: false
};

export default React.memo(CardScannedDocument);
