import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Fingerprint2 from "fingerprintjs2";
import PropTypes from "prop-types";
import {
  Form,
  Button,
  Modal,
  Icon,
  Loader,
  TextArea,
  Image,
} from "semantic-ui-react";
import { useCookies } from "react-cookie";

import * as Chat from "../Chat";
import * as CONSTANT from "../../../utils/constant";
import config from "../../../../config/config";
import usePushNotifications from "../Common/usePushNotifications";
import {
  useStateCallback,
  useDebouncedCallback,
} from "../../../utils/hooksUtils";
import _ from "../../../../react-lib/compat/lodashplus";

var globPostMessage = {};
const ChatBox = forwardRef((props, ref) => {
  const messageEmptyRef = useRef();
  const messageViewRef = useRef();
  const messageRef = useRef([]);
  const imageRef = useRef(null);
  const fileRef = useRef(null);
  const emptyRef = useRef(null);
  const scrollRef = useRef(false);
  const isMounted = useRef(true);
  const history = useHistory();
  const scrollTopRef = useRef(0);
  const scrollLeftRef = useRef(0);
  const dontScrollRef = useRef(false);

  const [cookies, setCookie] = useCookies([]);
  const [prevData, setPrevData] = useState([]); // use for scroll
  const [textMessage, setTextMessage] = useState("");
  const [message, setMessage] = useState([]); // set props.data as message;
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [postMessage, setPostMessage] = useStateCallback({});
  const [openVideoCallModal, setOpenVideoCallModal] = useState(false);
  const [videoCallID, setVideoCallID] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [patientId, setPatientId] = useState(null);
  const [patientHn, setPatientHn] = useState("-");
  const [readOnly, setReadOnly] = useState(false);

  // Channel detail
  const [channelDetail, setChannelDetail] = useState({});
  const [iframeHeight, setIframeHeight] = useState(480);
  const [iframeWidth, setIframeWidth] = useState(640);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationBody, setNotificationBody] = useState("");
  const [getMessageCancel, setGetMessageCancel] = useState(null);
  const [query, setQuery] = useState(null);
  const [readMessageFlag, setReadMessageFlag] = useState({});

  const [openImageModal, setOpenImageModal] = useState(false);
  const [image, setImage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const {
    userConsent,
    pushNotificationSupported,
    userSubscription,
    onClickAskUserPermission,
    onClickSusbribeToPushNotification,
    onClickSendSubscriptionToPushServer,
    pushServerSubscriptionId,
    onClickSendNotification,
    getSubscriptionObject,
  } = usePushNotifications();

  const location = useLocation();

  useEffect(() => {
    console.log("Chatbox readMessageFlag", readMessageFlag);
    if (readMessageFlag.chatChannelId && readMessageFlag.messageId) {
      handleReceivedMessageRead({
        chatChannelId: readMessageFlag.chatChannelId,
        messageId: readMessageFlag.messageId,
      });
    }
  }, [readMessageFlag.chatChannelId, readMessageFlag.messageId]);

  // const query = new URLSearchParams(useLocation().search);
  useEffect(() => {
    console.log(location.search, "useLocation().search");
    let qr = new URLSearchParams(location.search);
    setQuery(qr);
  }, [location.search]);

  useImperativeHandle(ref, () => ({
    receivedMessage: ({ chatChannelId }) => {
      console.log("Chatbox receivedMessage", { chatChannelId });
      handleReceivedMessage({ chatChannelId });
    },
    receivedMessageRead: ({ chatChannelId, messageId } = {}) => {
      setReadMessageFlag({ chatChannelId, messageId });
      // handleReceivedMessageRead({ chatChannelId, messageId });
    },
    getLastMessage: ({ chatChannelId } = {}) =>
      getLastMessage({ chatChannelId }),
    getChannelDetail: ({} = {}) => {
      return channelDetail;
    },
    callGetChannelDetail: () => {
      const channel = getChannelDetail().then((res) => {
        return res;
      });
      return channel;
    },
    receivedReclassify: ({ encounterId, filterValue, chatChannelId } = {}) => {
      if (
        props.match &&
        props.match.params &&
        (props.match.params.encounterId || props.match.params.chatChannelId)
      ) {
        let enId = props.match.params.encounterId;
        let chId = props.match.params.chatChannelId;
        if (
          parseInt(encounterId) === parseInt(enId) ||
          parseInt(chatChannelId) === parseInt(chId)
        ) {
          checkReadOnly({ filterValue });
          handleReceivedMessage({ chatChannelId });
        }
      }
    },
    checkReadOnly: ({ filterValue } = {}) => {
      checkReadOnly({ filterValue });
    },
  }));

  useEffect(() => {
    if (!props.apiToken && !cookies.apiToken) {
      props.noApiToken();
    }
    if (window.requestIdleCallback) {
      requestIdleCallback(function () {
        console.log("get fingerprint on requestIdleCallback");
        getFingerPrint();
      });
    } else {
      console.log("fallback with timeout");
      setTimeout(function () {
        getFingerPrint();
      }, 500);
    }

    return () => {
      isMounted.current = false;
      props.onSetChannelName({ name: "" });
    };
  }, []);

  useEffect(() => {
    checkBackToApp();
  }, [window.iosNative, window.MobNative]);

  const checkBackToApp = async () => {
    if (typeof window.iosNative !== "undefined") {
      try {
        window.iosNative.consoleLog("backToApp");
        const backToApp = await window.iosNative.backToApp;
        window.iosNative.consoleLog(backToApp);
        if (backToApp === "true") {
          // ios can only send string back
          window.iosNative.consoleLog("have back to app");
          props.onSetBackToApp(true);
        }
      } catch (e) {
        console.error(e);
        console.log(e.message);
      }
    } else if (typeof window.MobNative !== "undefined") {
      const backToApp = await window.MobNative.backToApp();
      if (backToApp) {
        props.onSetBackToApp(true);
      }
    }
  };

  const isWebRTCSupport = () => {
    if (window.navigator.userAgent.indexOf("Edge") > -1) {
      return false;
    }

    if (
      typeof navigator.mediaDevices === "object" &&
      typeof navigator.mediaDevices.getUserMedia !== "undefined"
    ) {
      return true;
    }

    return false;
  };

  const checkReadOnly = async ({ filterValue = "all" } = {}) => {
    console.log("=============checkReadOnly============");
    if (!props.match?.params.encounterId) {
      if (props.readOnly !== null) {
        setReadOnly(props.readOnly);
      } else {
        setReadOnly(false);
      }
      return;
    }
    const [data, error, network] =
      await props.controller.getEncounterTriageDetail({
        apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken,
        encounterId: props.match.params.encounterId,
      });
    if (data && isMounted.current) {
      if (data.can_chat) {
        props.enHasDiv();
        setReadOnly(false);
      } else {
        props.enHasNoDiv();
        setReadOnly(true);
      }
    }
  };

  useEffect(() => {
    setReadOnly(props.readOnly);
  }, [props.readOnly]);

  const handleReceivedMessageRead = async ({
    chatChannelId,
    messageId,
  } = {}) => {
    if (
      !props.match &&
      !props.match.params &&
      !props.match.params.chatChannelId
    ) {
      return;
    }
    if (!chatChannelId) {
      return;
    }

    if (
      parseInt(props.match.params.chatChannelId) === parseInt(chatChannelId)
    ) {
      let readMessage = message.findIndex((item) => item.id === messageId);
      if (readMessage > -1) {
        let newObj = {
          ...message[readMessage],
          user_read_count: message[readMessage].user_read_count + 1,
        };
        dontScrollRef.current = true;
        let newMessageState = [...message];
        newMessageState[readMessage] = newObj;
        scrollTopRef.current = messageViewRef.current.scrollTop;
        scrollLeftRef.current = messageViewRef.current.scrollLeft;
        setMessage(newMessageState);
        setReadMessageFlag({});
        setTimeout(() => {
          dontScrollRef.current = false;
          messageViewRef.current.scrollTo(
            scrollLeftRef.current,
            messageViewRef.current.scrollTop
          );
        }, 0);
      }
    }
  };

  const handleReceivedMessage = async ({ chatChannelId } = {}) => {
    if (
      !props.match &&
      !props.match.params &&
      !props.match.params.chatChannelId
    ) {
      return;
    }
    if (!chatChannelId) {
      return;
    }
    if (
      parseInt(props.match.params.chatChannelId) === parseInt(chatChannelId)
    ) {
      await getLastMessage();
    }
  };

  useEffect(() => {
    const getPrevMessage = async () => {
      if (
        !props.match &&
        !props.match.params &&
        !props.match.params.chatChannelId
      ) {
        return;
      }
      if (!prevUrl) {
        return;
      }
      if (message.length === 0 || !message) {
        return;
      }
      setOpenMenu(false);
      setIsLoading(true);
      const [response, error, network] =
        await props.controller.getMessageFromURL({
          url: prevUrl,
          apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken,
        });
      if (isMounted.current) {
        setIsLoading(false);
        if (response) {
          setPrevUrl(response.previous);
          let newArr = [...response.items, ...message];
          var result = newArr.reduce((unique, o) => {
            if (!unique.some((obj) => obj.id === o.id)) {
              unique.push(o);
            }
            return unique;
          }, []);
          setMessage(result);
        } else {
          setMessage([]);
        }
      }
    };

    const msgRef = messageViewRef.current;
    msgRef.onscroll = () => {
      scrollRef.current = true;
      if (msgRef.scrollTop === 0 && !dontScrollRef.current) {
        scrollTopRef.current = messageViewRef.current.scrollHeight;
        scrollLeftRef.current = messageViewRef.current.scrollLeft;
        setTimeout(() => {
          getPrevMessage();
        }, 0);
      }
    };
    return () => {
      scrollRef.current = false;
      msgRef.onscroll = null;
    };
  }, [message, prevUrl]);

  useEffect(() => {
    if (!dontScrollRef.current) {
      if (prevData.length === 0 || message.length <= prevData.length) {
        scrollToBottom();
      } else {
        messageViewRef.current.scrollTo(
          scrollLeftRef.current,
          messageViewRef.current.scrollHeight - scrollTopRef.current
        );
      }
    }
    setPrevData(message);
  }, [message]);

  useEffect(() => {
    console.log("ChatBox props.match : ", props.match);
    if (props.match?.params) {
      setMessage([]);
      setTextMessage("");
      if (props.match?.params?.chatChannelId) {
        getChannelDetail();
        getLastMessage();
      } else {
        setMessage([]);
      }
    }
    return () => cancelRequest();
  }, [props.match?.params?.chatChannelId, props.apiToken, cookies.apiToken]);

  const getChannelDetail = async () => {
    if (
      !props.match &&
      !props.match.params &&
      !props.match.params.chatChannelId
    ) {
      return {};
    }
    if (!cookies.apiToken && !props.apiToken) {
      return;
    }
    const [response, error, network] = await props.controller.getChannelDetail({
      chatChannelId: props.match?.params.chatChannelId,
      apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken,
    });
    if (isMounted.current) {
      if (response) {
        console.log(
          "getChannelDetail response ",
          response,
          config.IGNORE_CAN_CHAT
        );
        props.onSetChannelName({
          name: response.division_name || response.name,
        });
        if (!config.IGNORE_CAN_CHAT && response.can_chat === false) {
          setReadOnly(true);
        }
        setChannelDetail(response);
        return response;
      }
      return {};
    }
  };

  const cancelRequest = async () => {
    if (getMessageCancel) {
      getMessageCancel.cancel();
      setGetMessageCancel(null);
    }
  };

  const getLastMessage = async ({ chatChannelId = null } = {}) => {
    props.onCallGetLastMessage?.(
      props.match?.params?.chatChannelId,
      chatChannelId
    );
    // chatChannelId = props.match?.params?.chatChannelId || chatChannelId
    const source = axios.CancelToken.source();
    if (
      !props.match &&
      !props.match.params &&
      !props.match.params.chatChannelId
    ) {
      return;
    }
    if (!cookies.apiToken && !props.apiToken) {
      return;
    }

    const [response, error, network] =
      await props.controller.getChatChannelMessageList({
        chatChannelId: props.match?.params?.chatChannelId,
        last: true,
        apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken,
        cancelToken: source.token,
      });
    if (isMounted.current) {
      setGetMessageCancel(source);
      if (response) {
        setGetMessageCancel(null);
        setPrevUrl(response.previous);
        let result = response.items.reduce((unique, o) => {
          if (!unique.some((obj) => obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []);
        setMessage(result);
        postReadMessage();
      } else {
        setMessage([]);
      }
    }
  };

  const getFingerPrint = () => {
    var options = {};
    Fingerprint2.getPromise(options).then(function (components) {
      // components is array of {key: 'foo', value: 'component value'}
      console.log(components);
      var values = components.map(function (component) {
        return component.value;
      });
      var device_id = Fingerprint2.x64hash128(values.join(""), 31);
      console.log("Fingerprint id:", device_id);

      getSubscriptionObject()
        .then(function (subscription) {
          console.log("Success get notification subscription..");
          console.log(JSON.stringify(subscription));
          postUpdateSubscription(device_id, subscription);
        })
        .catch((err) => {
          console.error(
            "Couldn't create the notification subscription",
            err,
            "name:",
            err.name,
            "message:",
            err.message,
            "code:",
            err.code
          );
        });
    });
  };

  const postUpdateSubscription = async (device_id, subscription) => {
    console.log("murmur", device_id);
    const [response, error] = await props.controller.postUpdateSubscription({
      apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken,
      device_id,
      subscription,
    });
  };

  const postReadMessage = async () => {
    const [response, error] = await props.controller.postReadMessage({
      chatChannelId: props.match?.params.chatChannelId,
      apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken,
    });
  };

  const scrollToBottom = ({ behavior = "auto" } = {}) => {
    if (messageEmptyRef.current) {
      messageEmptyRef.current.scrollIntoView({ behavior });
    }
  };

  const handleSetSelectedMessage = (msgId) => {
    if (selectedMessage === msgId) {
      return setSelectedMessage(null);
    }
    return setSelectedMessage(msgId);
  };

  const handleOpenVideoCall = ({ url } = {}) => {
    console.log("handleOpenVideoCall");
    console.log(url);
    let vidCallId = url;
    if (vidCallId == null) {
      vidCallId = props.match?.params.chatChannelId;
    } else if (vidCallId.startsWith(config.WEB_RTC)) {
      // format ${config.WEB_RTC}/${videoCallID}/?name=${name}
      vidCallId = vidCallId.slice(config.WEB_RTC.length); // -> ${videoCallID}/?name=${name}
      vidCallId = vidCallId.split("/")[0]; // -> ${videoCallID}
    }
    if (isWebRTCSupport()) {
      if (query.get("vid_call") !== "true") {
        history.push("?vid_call=true");
      }
      setVideoCallID(vidCallId);
      setOpenVideoCallModal(true);
    } else {
      if (typeof window.iosNative !== "undefined") {
        setVideoCallID(vidCallId);
        window.iosNative.onVideoCall(
          `${config.WEB_RTC}/${vidCallId}/?name=${name}`
        );
        return;
      } else {
        alert("เบราว์เซอร์ ไม่รองรับวีดีโอคอล");
      }
    }
  };

  const handleCloseVideoCall = ({ sendEndMessage = true }) => {
    
    if (sendEndMessage) {
      setTimeout(() => {
        sendMessage({
          contentType: "end_call",
          content: "", // `${config.WEB_RTC}/${props.match.params.chatChannelId}/?name=${name}`
        });
      }, 1000);
    }

    setTimeout(() => {
      history.replace("?vid_call=false");
      setOpenVideoCallModal(false);
    }, 100);
  };

  const isMobile = () => {
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return isMobile;
  };

  const handleOpenVideoCallFromMessage = ({ url }) => {
    // if(config.HIDE_CALL_BUTTON){
    //   return
    // }
    let qr = new URLSearchParams(`?vid_call=true&content=${url}`);
    setQuery(qr);
  };

  const genMessage = () => {
    console.log("genMessage ", props?.userId, message);
    if (!props?.userId) {
      return;
    }

    return message.map((item, index) => {
      let chat = (
        <Chat.FriendMessage
          message={item}
          isMobile={isMobile()}
          isSelected={selectedMessage === item.id}
          onSelect={handleSetSelectedMessage}
          onOpenVideoCall={handleOpenVideoCallFromMessage}
          onNavigationMessage={props.onNavigationMessage}
          onMobileOpenImage={handleMobileOpenImage}
        />
      );
      if (item.author && item.author.id == props.userId) {
        chat = (
          <Chat.MyMessage
            message={item}
            isMobile={isMobile()}
            isSelected={selectedMessage === item.id}
            onSelect={handleSetSelectedMessage}
            onResend={handleResendMessage}
            onOpenVideoCall={handleOpenVideoCallFromMessage}
            onNavigationMessage={props.onNavigationMessage}
            onMobileOpenImage={handleMobileOpenImage}
          />
        );
      }
      return (
        <div key={item.id} ref={(el) => (messageRef.current[index] = el)}>
          {chat}
        </div>
      );
    });
  };

  const handleMobileOpenImage = (url) => {
    setOpenImageModal(true);
    setImage(url);
  };

  const handleResendMessage = async ({ message } = {}) => {
    if (!message) {
      return;
    }
    if (
      !props.match &&
      !props.match.params &&
      !props.match.params.chatChannelId
    ) {
      return;
    }
    let chatChannelId = props.match?.params.chatChannelId;
    let index = postMessage[chatChannelId]
      ? postMessage[chatChannelId].findIndex((item) => item.id === message.id)
      : -1;
    let newObj = { ...postMessage };
    let newArr = postMessage[chatChannelId]
      ? [...postMessage[chatChannelId]]
      : [];
    if (index > -1) {
      newArr[index].sendFail = false;
    }
    newObj[chatChannelId] = newArr;
    await setPostMessage(newObj);
    setTimeout(async () => {
      const [response, error, network] =
        await props.controller.postChatChannelMessage({
          content: message.content,
          contentType: message.content_type,
          contentFile: message.content_file,
          chatChannelId: props.match?.params.chatChannelId,
          apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken,
        });
      if (response) {
        await getLastMessage();
        let newObj = { ...postMessage };
        let newArr = postMessage[chatChannelId]
          ? [...postMessage[chatChannelId]]
          : [];
        let arr = newArr.filter((item) => item.id !== message.id);
        newObj[chatChannelId] = arr;
        setPostMessage(newObj);
      } else {
        let newObj = { ...postMessage };
        let newArr = postMessage[chatChannelId]
          ? [...postMessage[chatChannelId]]
          : [];
        let index = newArr.findIndex((item) => item.id === message.id);
        if (index != -1) {
          newArr[index].sendFail = true;
        }
        newObj[chatChannelId] = newArr;
        setPostMessage(newObj);
      }
    }, 0);
    setTextMessage("");
    scrollToBottom({ behavior: "smooth" });
  };

  const genPostMessage = () => {
    let chatChannelId = props.match?.params?.chatChannelId;
    if (!chatChannelId || !postMessage[chatChannelId]) {
      return;
    }
    return postMessage[chatChannelId].map((item, index) => {
      return (
        <div key={item.id} ref={(el) => (messageRef.current[index] = el)}>
          <Chat.MyMessage
            message={item}
            isSelected={selectedMessage === item.id}
            onSelect={handleSetSelectedMessage}
            onResend={handleResendMessage}
            onNavigationMessage={props.onNavigationMessage}
          />
        </div>
      );
    });
  };

  const handleCallRequest = async () => {
    let name = props.fullname ? props.fullname : cookies.fullname;
    if (isWebRTCSupport()) {
      let response = await sendMessage({
        contentType: "vid_call",
        content: "",
      });
      let url = null;
      if (response) {
        url = response.content;
      }
      if (url == null) {
        // backward compatible
        url = `${config.WEB_RTC}/${props.match?.params.chatChannelId}/?name=${name}`;
      }
      history.push(`?vid_call=true&content=${response.content}`);
      // handleOpenVideoCall({url});
    } else {
      if (typeof window.iosNative !== "undefined") {
        let name = props.fullname ? props.fullname : cookies.fullname;
        let response = await sendMessage({
          contentType: "vid_call",
          content: "",
        });
        let url = response.content;
        if (response.content == null) {
          // backward compatible
          url = `${config.WEB_RTC}/${props.match.params.chatChannelId}/?name=${name}`;
        } else {
          url = `${config.WEB_RTC}/${response.content}/?name=${name}`;
        }
        window.iosNative.onVideoCall(url);
      } else {
        alert("เบราว์เซอร์ ไม่รองรับวีดีโอคอล");
      }
    }
  };

  const sendMessage = ({ contentType, content } = {}) => {
    return new Promise((resolve, reject) => {
      let txtContent = content ? content : textMessage;
      if (
        contentType !== "vid_call" &&
        contentType !== "end_call" &&
        !txtContent
      ) {
        return;
      }
      if (
        !props.match &&
        !props.match.params &&
        !props.match.params.chatChannelId
      ) {
        return;
      }
      let chatChannelId = props.match.params.chatChannelId;
      let id = new Date().toISOString();
      let msg = postMessage[chatChannelId]
        ? [...postMessage[chatChannelId]]
        : [];
      // let postMsg = {
      //   ...postMessage,
      //   [chatChannelId]: [
      //     ...msg,
      //     {
      //       id: id,
      //       author: {
      //         id: props.userId
      //       },
      //       content_file: txtContent,
      //       content: txtContent,
      //       content_type: contentType ? contentType : "text",
      //       sendFail: false
      //     }
      //   ]
      // };
      if (!globPostMessage[chatChannelId]) {
        globPostMessage[chatChannelId] = [];
      }
      globPostMessage[chatChannelId].push({
        id: id,
        author: {
          id: props.userId,
        },
        content_file: txtContent,
        content: txtContent,
        content_type: contentType ? contentType : "text",
        sendFail: false,
      });
      setTextMessage("");
      var response, error, network;
      setPostMessage({ ...globPostMessage }, async () => {
        [response, error, network] =
          await props.controller.postChatChannelMessage({
            content: txtContent,
            contentFile: txtContent,
            contentType: contentType ? contentType : "text",
            chatChannelId: props.match.params.chatChannelId,
            apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken,
          });
        if (isMounted.current) {
          if (response) {
            getLastMessage();
            // let newObj = { ...postMessage, ...postMsg };
            // let newArr = newObj[chatChannelId] ? [...newObj[chatChannelId]] : [];
            let index = globPostMessage[chatChannelId].findIndex(
              (item) => item.id === id
            );
            globPostMessage[chatChannelId].splice(index, 1);
            // newObj[chatChannelId] = newArr;
            setPostMessage(globPostMessage);
          } else {
            // let newObj = { ...postMessage, ...postMsg };
            // let newArr = newObj[chatChannelId] ? [...newObj[chatChannelId]] : [];
            let index = globPostMessage[chatChannelId].findIndex(
              (item) => item.id === id
            );
            if (index !== -1) {
              globPostMessage[chatChannelId][index].sendFail = true;
              // newObj[chatChannelId] = newArr;
            }
            setPostMessage(globPostMessage);
          }
        }
        resolve(response);
      });
    });
  };

  useEffect(() => {
    scrollToBottom({ behavior: "smooth" });
  }, [postMessage]);

  const handleUploadImage = async (e) => {
    e.preventDefault();

    var fileList = e.target.files;
    if (fileList?.length > 0) {

      // console.log("fileList[0].name", fileList[0].name);

      for (const file of fileList) {
        // Not supported in Safari for iOS.
        const name = file.name ? file.name : 'NOT SUPPORTED';
        // Not supported in Firefox for Android or Opera for Android.
        const type = file.type ? file.type : 'NOT SUPPORTED';
        // Unknown cross-browser support.
        const size = file.size ? file.size : 'NOT SUPPORTED';
        console.log({file, name, type, size});
      }

      if ( fileList[0].name.includes("image.")) {
        let newName = new Date().toJSON().replace(".","") + ".jpg";
        console.log(" Rename to ", newName);
        var renameFile = new File([fileList[0]], newName, {
          type: fileList[0].type,
          lastModified: fileList[0].lastModified,
        });
        sendMessage({ contentType: "image", content: renameFile });
      } else {
        // console.log(" Not rename");
        sendMessage({ contentType: "image", content: fileList[0] });
      }
    }
   
  };

  const handleUploadFile = async (e) => {
    e.preventDefault();
    console.log(e.target.files, "files");
    // const file = await toBase64(e.target.files[0]);
    // if (e.target.files[0]) {
    sendMessage({ contentType: "file", content: e.target.files[0] });
    // }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleResize = () => {
    setTimeout(() => {
      setIframeHeight(window.innerHeight - 100);
      setIframeWidth(window.innerWidth - 10);
    }, 500);
  };

  const handleIframeMessage = (msg) => {
    try {
      let jsonMsg = JSON.parse(msg.data);
      if (jsonMsg.message === "end_call") {
        handleCloseVideoCall({ sendEndMessage: false });
      }
    } catch (e) {
      console.log("ignore this message");
    }
  };

  const onLoadIfrm = () => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("message", handleIframeMessage);
    handleResize();
  };

  useEffect(() => {
    if (openMenu) {
      emptyRef.current.scrollIntoView({ behavior: "auto" });
    }
  }, [openMenu]);

  useEffect(() => {
    if (props.patientData.patient) {
      console.log(props);
      setPatientId(props.patientData.patient);
      if (props.location.search) {
        let urlParams = new URLSearchParams(props.location.search);
        let hn = urlParams.get("hn");
        if (hn !== null && hn !== "") {
          setPatientHn("hn_" + hn);
        } else {
          setPatientHn("id_" + props.patientData.patient);
        }
      }
    }
  }, [props.patientData.patient]);

  useEffect(() => {
    if (patientId && channelDetail.division) {
      checkReadOnly();
    }
  }, [patientId, channelDetail.division]);

  useEffect(() => {
    if (!query) {
      return;
    }
    if (query.get("vid_call") === "true") {
      // console.error("===============\nNot implemented\n===============");
      const url = query.get("content");
      handleOpenVideoCall({ url });
    } else {
      setOpenVideoCallModal(false);
    }
  }, [query]);

  let name = props.fullname ? props.fullname : cookies.fullname;

  useEffect(() => {
    if (!openImageModal) {
      setImage(false);
    }
  }, [openImageModal]);

  return (
    <div
      key={props.match?.params?.chatChannelId}
      // style={chatStyle.chatBox}
      className="ChatBox"
    >
      <Modal open={openImageModal} onClose={() => setOpenImageModal(false)}>
        <Image src={image} />
      </Modal>
      {/* {notificationTitle ? (
        <Notification
          title={notificationTitle}
          options={{
            icon: 'http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png',
            body: {notificationBody},
          }}
          onClose={() => setNotificationTitle(undefined)}
        />
      ) : null} */}

      <div
        className="messageView"
        ref={messageViewRef}
        onClick={() => {
          if (scrollRef.current) {
            return;
          }
          setOpenMenu(false);
        }}
      >
        <div
          className="chat-loader"
          style={{ display: isLoading ? "block" : "none" }}
        >
          <Loader active={isLoading} />
        </div>
        {genMessage()}
        {genPostMessage()}
        <div ref={messageEmptyRef}></div>
      </div>

      <Form className="ChatBoxChoice">
        <Form.Group>
          <Form.Field>
            <Button
              type="button"
              disabled={readOnly}
              icon="image"
              size="huge"
              compact
              onClick={() => imageRef.current.click()}
            />
          </Form.Field>
          <Form.Field>
            <Button
              type="button"
              disabled={readOnly}
              icon="call"
              size="huge"
              compact
              onClick={useCallback(
                _.debounce(handleCallRequest, 1000, { leading: true }),
                [props.match?.params?.chatChannelId]
              )}
            />
          </Form.Field>
          <Form.Field>
            <Button
              disabled={readOnly}
              size="huge"
              icon="file"
              compact
              onClick={() => fileRef.current.click()}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={imageRef}
        onChange={(e) => handleUploadImage(e)}
      />
      <input
        type="file"
        accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.skt"
        style={{ display: "none" }}
        ref={fileRef}
        onChange={(e) => handleUploadFile(e)}
      />

      <div className="textBox">
        <Button
          type="button"
          disabled={readOnly}
          className="sendButton"
          circular
          icon="list"
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        />
        <TextArea
          disabled={readOnly}
          rows={1}
          data-testid="chat"
          className={readOnly ? "readOnly" : ""}
          placeholder={
            readOnly
              ? "ไม่สามารถส่งข้อความได้ เนื่องจากไม่มีสิทธิ"
              : props.placeholder
          }
          onKeyDown={(e) => {
            if (!props.useNormalTextAreaKey) {
              if (e.keyCode === CONSTANT.KEY_CODE.ENTER && e.shiftKey) {
                e.preventDefault();
                setTextMessage(textMessage + "\n");
              } else if (e.keyCode === CONSTANT.KEY_CODE.ENTER) {
                e.preventDefault();
                sendMessage();
              }
            }
          }}
          value={textMessage}
          onChange={(e) => setTextMessage(e.target.value)}
        />
        <Button
          type="button"
          disabled={readOnly}
          className="sendButton"
          circular
          icon="send"
          onClick={sendMessage}
        />
      </div>

      {openMenu && (
        <div className="menu-div">
          {!config.HIDE_CALL_BUTTON && (
            <Button
              type="button"
              className="sendButton"
              circular
              icon="call"
              onClick={handleCallRequest}
              // onClick={React.useCallback(_.debounce(handleCallRequest, 1000, { leading: true }), [])}
            />
          )}
          <Button
            type="button"
            className="sendButton"
            circular
            icon="image"
            onClick={() => {
              if (typeof window.MobNative !== "undefined") {
                window.MobNative.consoleLog("IMAGE CLICK");
              }
              console.log("image click");
              imageRef.current.click();
            }}
          />
          <Button
            type="button"
            className="sendButton"
            circular
            icon="file"
            // onClick={() => fileRef.current.click()}
            onClick={() => {
              if (typeof window.MobNative !== "undefined") {
                window.MobNative.consoleLog("FILE CLICK");
              }
              console.log("file click");
              fileRef.current.click();
            }}
          />
          <div ref={emptyRef}></div>
        </div>
      )}

      <Modal
        basic
        centered={false}
        dimmer={"inverted"}
        open={openVideoCallModal}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        size="fullscreen"
        style={{ color: "white" }}
      >
        <Modal.Header>
          <Button
            compact
            negative
            onClick={useCallback(
              _.debounce(handleCloseVideoCall, 1000, { leading: true }),
              [props.match?.params.chatChannelId]
            )}
          >
            End call{" "}
            <Icon
              name="call"
              circular
              inverted
              color="red"
              style={{ transform: "rotate(225deg)", margin: 0 }}
            />
          </Button>
        </Modal.Header>
        <Modal.Content>
          <iframe
            frameBorder={1}
            onLoad={onLoadIfrm}
            scrolling="no"
            allow="microphone; camera; *;"
            src={`${config.WEB_RTC}/${videoCallID}/?name=${name}&isPatient=${props.isPatient}&hn=${patientHn}`}
            width={iframeWidth}
            height={iframeHeight}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
});

ChatBox.defaultProps = {
  data: [],
  userId: null,
  onSendMessage: () => {},
  noApiToken: () => {},
  postData: [],
  useNormalTextAreaKey: false,
  placeholder: "Enter Your Message...",
  onSetChannelName: () => {},
  fullname: null,
  username: null,
  nullId: false,
  onNavigationMessage: ({ content = "" }) => content,
  patientData: {},
  enHasNoDiv: () => {},
  readOnly: null,
  enHasDiv: () => {},
  onBackToApp: () => {},
  isPatient: true,
  onSetBackToApp: () => {},
  onCallGetLastMessage: () => {},
};

ChatBox.propTypes = {
  data: PropTypes.array,
  userId: PropTypes.string,
  onSendMessage: PropTypes.func,
  useNormalTextAreaKey: PropTypes.bool,
  placeholder: PropTypes.string,
  onSetChannelName: PropTypes.func,
  noApiToken: PropTypes.func,
  fullname: PropTypes.string,
  username: PropTypes.string,
  nullId: PropTypes.bool,
  onNavigationMessage: PropTypes.func,
  patientData: PropTypes.object,
  enHasNoDiv: PropTypes.func,
  readOnly: PropTypes.bool,
  enHasDiv: PropTypes.func,
  onBackToApp: PropTypes.func,
  isPatient: PropTypes.bool,
  onCallGetLastMessage: PropTypes.func,
};

export default React.memo(ChatBox);
