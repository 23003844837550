import BaseService from "./BaseService";
import { MSG } from "../../configs/apis";
class MSGService extends BaseService {
  /**
   * get operating order
   * @param {object} data
   */
  getLatestChatChannel({ params, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(MSG.LATEST_CHAT_CHANNEL, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getChatChannelMessageList({ params, apiToken, chatChannelId, cancelToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = MSG.CHAT_CHANNEL_MESSAGE_LIST({ chatChannelId });
    return this.client
      .get(url, { params, cancelToken })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  getMessageFromURL({ params, apiToken, url } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    return this.client
      .get(url, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postChatChannelMessage({ params, data, apiToken, chatChannelId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = MSG.CHAT_CHANNEL_MESSAGE_POST({ chatChannelId });
    return this.client
      .post(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postReadMessage({ params, data, apiToken, chatChannelId } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = MSG.CHAT_CHANNEL_MESSAGE_READ_COUNT({ chatChannelId });
    return this.client
      .post(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
  postUpdateSubscription({ params, data, apiToken } = {}) {
    if (apiToken) {
      this.client.defaults.headers["Authorization"] = "Token " + apiToken;
    }
    let url = MSG.DEVICE_UPDATE();
    return this.client
      .post(url, data, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
export default MSGService;
